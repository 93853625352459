<template>
  <v-container fluid class="scroll-y">
    <v-col cols="12">
      <v-row justify="center">
        <v-card outlined rounded="lg" class="elevation-1" width="650">
          <!-- <div style="position: sticky; top: 5.5em; z-index: 1;"> -->
            <v-toolbar
              flat
              height="64"
              class="rounded-t-lg"
              id="scroll-target"
            >
              <v-toolbar-title class="text-h5 font-weight-medium">Pesan Masuk</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
          <!-- </div> -->

          <v-fab-transition>
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="red darken-4"
              @click="toTop"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-sheet outlined class="rounded-lg ma-3">
            <v-list two-line class="rounded-lg">
              <v-list-item-group
                active-class="red--text text--darken-4"
                mandatory
              >
                <template v-for="(item, index) in items">
                  <!-- <v-subheader
                    v-if="item.header"
                    :key="item.header"
                    v-text="item.header"
                  ></v-subheader> -->

                  <v-list-item
                    :key="item.From"
                    @click="DialogDetailPesanMasuk = true"
                  >
                    <!-- <v-list-item-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                      <v-img
                        :src="item.Photo"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar> -->

                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters>
                          <span class="col-8 text-truncate" v-text="item.From"></span>
                          <v-spacer></v-spacer>
                          <span class="caption d-flex justify-end" v-text="item.Date"></span>
                        </v-row>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <v-row no-gutters>
                          <span class="text--primary" v-text="item.TitleMessage"></span>
                          <v-spacer></v-spacer>
                          <!-- <v-icon small color="error">mdi-circle</v-icon> -->
                        </v-row>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        <span v-text="item.Content"></span>
                      </v-list-item-subtitle>

                      <!-- <v-list-item-subtitle v-text="item.Content"></v-list-item-subtitle> -->
                    </v-list-item-content>

                    <!-- <v-list-item-action>
                      <v-list-item-action-text>
                        <span class="caption" v-text="item.Date"></span>
                      </v-list-item-action-text>
                      <v-chip small color="error">Baru</v-chip>
                    </v-list-item-action> -->
                  </v-list-item>

                  <v-divider
                    inset
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-sheet>
        </v-card>
      </v-row>
    </v-col>

    <v-dialog
      v-model="DialogDetailPesanMasuk"
      width="400"
    >
      <v-card outlined rounded="lg">
        <v-toolbar
          flat
          height="64"
        >
          <v-toolbar-title class="text-h5">Pengajuan Cuti</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="DialogDetailPesanMasuk = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg">
              <div>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Dibuat Oleh</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.karyawan.Nama"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Diajukan Tanggal</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.TanggalPengajuan"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Dari Tanggal</v-list-item-title>
                    <v-row dense no-gutters>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.TanggalMulai"/></div>
                      <div class="mr-2 ml-2">S/D</div>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.TanggalSelesai"/></div>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Jam Mulai</v-list-item-title>
                    <v-row dense no-gutters>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.JamMulai"/></div>
                      <div class="mr-2 ml-2">S/D</div>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.JamSelesai"/></div>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Jenis Cuti</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.JenisCuti"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Alasan Cuti</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.AlasanCuti"/>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        fab: false,
        DialogDetailPesanMasuk: false,
        DetailMsg:{
          Color: 'green darken-3',
          AlasanCuti:'Sakit',
          DibuatOleh:'',
          DibuatTgl:'2021-10-25',
          DiubahOleh:'',
          DiubahTgl:'2021-10-25',
          JamMulai:'08:00',
          JamSelesai:'17:00',
          JenisCuti:'Cuti Tahunan',
          KodeKaryawan:'',
          NoCuti:'',
          StatusCuti:'Disetujui',
          TanggalMulai:'2021-10-25',
          TanggalPengajuan:'2021-10-25',
          TanggalSelesai:'2021-10-25',
          id:'',
          karyawan:{
            Nama:'Endhyarnie Isma Dinata'
          }
        },
        

        items: [
          // { header: 'Hari Ini' },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown',
            Date: '2021-10-25',
            TitleMessage: 'Pengajuan Cuti',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr rdrsrewrw erar3rwr',
            From: 'From1',
            StatusAppruval: 'Disetujui',
            Color: 'green darken-3',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale',
            Date: '2021-10-25',
            TitleMessage: 'Pengajuan Cuti',
            Content: `fsegrhtdhqrwtyujx wretyud6djsw rerqe setees`,
            From: 'From2',
            StatusAppruval: 'Diajukan',
            Color: 'orange',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale',
            Date: '2021-10-25',
            TitleMessage: 'Pengajuan Cuti',
            Content: `Oke`,
            From: 'From3',
            StatusAppruval: 'Tidak Disetujui',
            Color: 'error',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From4',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From5',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From6',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From7',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From8',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From9',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
          {
            // Photo: 'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned',
            Date: '2021-10-25',
            TitleMessage: 'Perpanjang Kontrak',
            Content: 'Do you have Paris recommendations? Have you ever been? esgheraw qqwerwarqeq qwragqerqgaeqwqe qrwagweqqeqeqrwr',
            From: 'From10',
            StatusAppruval: 'Diperpanjang',
            Color: 'primary',
          },
        ],
      }
    },

    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      }
    },
  }
</script>